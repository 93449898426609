import { createGlobalStyle } from 'styled-components';
import '@govbr-ds/core/dist/core.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
    
    .flatpickr-current-month {
      display: flex;
      align-items: center;
    }

    .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
      padding-top: 16px;
    }

    .flatpickr-time.time24hr {
      margin: 1em 0 0 !important;
    }
  }


  html {
    font-size: 62.5%;
  }

  body, input, button {
    font-size: 1.6rem;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  .h2 { 
    color: var(--color-primary-darken-01); 
  }

  #root {
    height: 100vh;
  }

  button {
    cursor: pointer;
  }

`;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface INavCardProps {
  title: string;
  icon: string;
  route: string;
}

export const NavCard: React.FC<INavCardProps> = ({ title, icon, route }) => {
  const navigate = useNavigate();

  const handleNavigate = useCallback(() => navigate(route), []);

  return (
    <div className="br-card" onClick={handleNavigate}>
      <div className="card-content">
        <i className={icon} aria-hidden="true" />
        <p className="text-weight-medium text-up-01">{title}</p>
      </div>
    </div>
  );
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToggle } from '../../hooks/useToggle';
import { IRoutes } from '../../routes/types';

type NavItemProps = IRoutes;

export const NavItem = ({
  name,
  nameEn,
  path,
  icon,
  children,
  hidden,
}: NavItemProps) => {
  const { value: isFolderOpen, toggle } = useToggle(false);
  const { i18n } = useTranslation();

  if (!children) {
    return (
      <NavLink className="menu-item divider" to={path || ''}>
        <span className="icon">
          <i className={icon} aria-hidden="true" />
        </span>
        <span className="content">
          {i18n.language === 'pt' ? name : nameEn}
        </span>
      </NavLink>
    );
  }

  return (
    <div
      className={`menu-folder
      ${children ? 'drop-menu' : ''}
      ${isFolderOpen ? 'active' : ''}`}
      style={{
        cursor: 'pointer',
      }}
    >
      <a className="menu-item" onClick={toggle}>
        <span className="icon">
          <i className={icon} aria-hidden="true" />
        </span>
        <span className="content">
          {i18n.language === 'pt' ? name : nameEn}
        </span>
        <span className="support">
          <i className="fas fa-angle-down" aria-hidden="true" />
        </span>
      </a>
      <ul>
        {children.map(
          (item) =>
            !item.hidden && (
              <li key={item.name}>
                <NavLink
                  className="menu-item"
                  to={`${path}/${item?.path ?? ''}`.replace(/\/$/, '')}
                  end
                >
                  <span className="icon">
                    <i className={item.icon} aria-hidden="true" />
                  </span>
                  <span className="content">
                    {i18n.language === 'pt' ? item.name : item.nameEn}
                  </span>
                </NavLink>
              </li>
            ),
        )}
      </ul>
    </div>
  );
};

import React, { lazy } from 'react';
import { Icons } from '../utils/Icons';
import { IRoutes } from './types';
import { useAuth } from '../context/Auth';
import HistoricoAsset from '../pages/HistoricoAsset';
import APITokens from '../pages/APITokens';
import CriarToken from '../pages/APITokens/Register';
import APITokensAdmin from '../pages/APITokensAdmin';
import CriarTokenAdmin from '../pages/APITokensAdmin/Register';

// const Home = lazy(() => import('../pages/Home'));

const Lotes = lazy(() => import('../pages/Lotes'));
const RegisterLotes = lazy(() => import('../pages/Lotes/Register'));

// const Gasodutos = lazy(() => import('../pages/Gasodutos'));
// const RegisterGasodutos = lazy(() => import('../pages/Gasodutos/Register'));

const PontosEntrega = lazy(() => import('../pages/PontosEntrega'));
const RegisterPontosEntrega = lazy(
  () => import('../pages/PontosEntrega/Register'),
);
const AddPontoEntrega = lazy(
  () => import('../pages/PontosEntrega/AddPontosEntrega'),
);

const MetodosEntrega = lazy(() => import('../pages/MetodosEntrega'));
const RegisterMetodoEntrega = lazy(
  () => import('../pages/MetodosEntrega/Register'),
);

const Produtos = lazy(() => import('../pages/Produtos'));
const RegisterProduto = lazy(() => import('../pages/Produtos/Register'));

const Plantas = lazy(() => import('../pages/Plantas'));
const RegisterPlantas = lazy(() => import('../pages/Plantas/Register'));

const PropostasCompra = lazy(() => import('../pages/OfertasCompra'));
const RegisterOferta = lazy(() => import('../pages/OfertasCompra/Register'));
const PropostasCompraAdmin = lazy(() => import('../pages/OfertasCompraAdmin'));
const VisualizarOferta = lazy(() => import('../pages/OfertasCompra/View'));

const Negociacoes = lazy(() => import('../pages/Negociacoes'));
const VisualizarNegociacao = lazy(() => import('../pages/Negociacoes/View'));
const MensagensNegociacao = lazy(() => import('../pages/Negociacoes/Messages'));

const PropostasVenda = lazy(() => import('../pages/PropostasVenda'));
const ViewPropostaVenda = lazy(() => import('../pages/PropostasVenda/View'));
const ViewContratoProposta = lazy(
  () => import('../pages/PropostasVenda/ViewContratoProposta'),
);
const PropostasVendaAdmin = lazy(() => import('../pages/PropostasVendaAdmin'));
const RegistrarContratoProposta = lazy(
  () => import('../pages/PropostasVenda/RegisterContrato'),
);

const Estoque = lazy(() => import('../pages/Estoque'));

const RegistroComercializacao = lazy(
  () => import('../pages/RegistroComercializacao'),
);

const HistoricoLotes = lazy(() => import('../pages/HistoricoLotes'));

const Saldo = lazy(() => import('../pages/Saldo'));

const Aposentadoria = lazy(() => import('../pages/AposentarTokens'));
const TokensAposentados = lazy(() => import('../pages/Aposentadorias'));

const Registrantes = lazy(() => import('../pages/Registrantes'));
const RegistrarRegistrantes = lazy(
  () => import('../pages/Registrantes/Register'),
);

const Participantes = lazy(() => import('../pages/Participantes'));
const RegistrarParticipantes = lazy(
  () => import('../pages/Participantes/Register'),
);

const Certificadores = lazy(() => import('../pages/Certificadores'));
const RegistrarCertificadores = lazy(
  () => import('../pages/Certificadores/Register'),
);

const Usuarios = lazy(() => import('../pages/Usuarios'));
const RegistrarUsuarios = lazy(() => import('../pages/Usuarios/Register'));

const Dashboard = lazy(() => import('../pages/Dashboard'));

export const PrivateRoutes: IRoutes[] = [
  {
    name: 'Usuários',
    nameEn: 'Users',
    path: '/usuarios',
    authorizedRoles: ['administrador'],
    icon: Icons.USUARIO,
    type: 'geral',
    hidden: true,
    children: [
      {
        path: ':id',
        name: 'Gerenciar Usuários',
        nameEn: 'Manage Users',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <Usuarios />,
        type: 'geral',
      },
      {
        path: 'registrar/:id',
        name: 'Registrar Usuários',
        nameEn: 'Register Users',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegistrarUsuarios />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Certificadores',
    nameEn: 'Certifiers',
    path: 'certificadores',
    authorizedRoles: ['administrador'],
    icon: Icons.CERTIFICADOR,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Gerenciar Certificadores',
        nameEn: 'Manage Certifiers',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <Certificadores />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Certificadores',
        nameEn: 'Register Certifiers',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegistrarCertificadores />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Registrantes',
    nameEn: 'Registrants',
    path: 'registrantes',
    authorizedRoles: ['administrador'],
    icon: Icons.REGISTRANTE,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Gerenciar Registrantes',
        nameEn: 'Manage Registrants',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <Registrantes />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Registrantes',
        nameEn: 'Register Registrants',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegistrarRegistrantes />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Participantes',
    nameEn: 'Participants',
    path: 'participantes',
    authorizedRoles: ['administrador'],
    icon: Icons.PARTICIPANTE,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Gerenciar participantes',
        nameEn: 'Manage Participants',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <Participantes />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Participante',
        nameEn: 'Register Participants',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegistrarParticipantes />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Lotes',
    nameEn: 'Batchs',
    path: 'lotes',
    authorizedRoles: ['registrante', 'certificador'],
    icon: Icons.LOTES,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Listar Lotes',
        nameEn: 'List Batchs',
        authorizedRoles: ['registrante', 'certificador'],
        icon: Icons.LISTAR,
        element: <Lotes />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Lote',
        nameEn: 'Register Batchs',
        authorizedRoles: ['registrante'],
        icon: Icons.ADICIONAR,
        element: <RegisterLotes />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Pontos de Entrega',
    nameEn: 'Delivery Points',
    path: 'pontos-entrega',
    authorizedRoles: ['administrador', 'participante'],
    icon: Icons.PONTOENTREGA,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Listar Pontos de Entrega',
        nameEn: 'List Delivery Points',
        authorizedRoles: ['administrador', 'participante'],
        icon: Icons.LISTAR,
        element: <PontosEntrega />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Ponto de Entrega',
        nameEn: 'Register Delivery Points',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegisterPontosEntrega />,
        type: 'geral',
      },
      {
        path: 'selecionar',
        name: 'Selecionar Pontos de Entrega',
        nameEn: 'Select Delivery Points',
        authorizedRoles: ['participante'],
        icon: Icons.ADICIONAR,
        element: <AddPontoEntrega />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Métodos de Entrega',
    nameEn: 'Delivery Methods',
    path: 'metodos-entrega',
    authorizedRoles: ['administrador'],
    icon: Icons.METODOENTREGA,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Listar Métodos de Entrega',
        nameEn: 'List Delivery Methods',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <MetodosEntrega />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Método de Entrega',
        nameEn: 'Register Delivery Methods',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegisterMetodoEntrega />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Produtos',
    nameEn: 'Products',
    path: 'produtos',
    authorizedRoles: ['administrador'],
    icon: Icons.PRODUTOS,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Listar Produtos',
        nameEn: 'List Products',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <Produtos />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Produto',
        nameEn: 'Register Product',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <RegisterProduto />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Plantas',
    nameEn: 'Plants',
    path: 'plantas',
    authorizedRoles: ['registrante', 'admin'],
    icon: Icons.PLANTA,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Listar Plantas',
        nameEn: 'List Plants',
        authorizedRoles: ['registrante', 'admin'],
        icon: Icons.LISTAR,
        element: <Plantas />,
        type: 'geral',
      },
      {
        path: 'registrar',
        name: 'Registrar Plantas',
        nameEn: 'Register Plants',
        authorizedRoles: ['registrante'],
        icon: Icons.ADICIONAR,
        element: <RegisterPlantas />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Ofertas',
    nameEn: 'Offers',
    path: 'ofertas',
    authorizedRoles: ['registrante', 'participante'],
    icon: Icons.PROPOSTA_VENDA,
    type: 'mercado',
    children: [
      {
        index: true,
        name: 'Ofertas',
        nameEn: 'List Offers',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.LISTAR,
        element: <PropostasCompra />,
        type: 'mercado',
      },
      {
        path: 'registrar',
        name: 'Registrar oferta',
        nameEn: 'Register offer',
        authorizedRoles: ['participante', 'registrante'],
        icon: Icons.ADICIONAR,
        element: <RegisterOferta />,
        type: 'mercado',
      },
      {
        path: 'visualizar/:id',
        name: 'Visualizar oferta',
        nameEn: 'View offer',
        authorizedRoles: ['registrante', 'participante'],
        hidden: true,
        icon: Icons.ADICIONAR,
        element: <VisualizarOferta />,
        type: 'mercado',
      },
    ],
  },
  {
    name: 'Propostas de venda',
    nameEn: 'Sell Proposals',
    path: 'propostas-venda',
    authorizedRoles: ['registrante', 'participante'],
    icon: Icons.PROPOSTA_VENDA,
    type: 'mercado',
    children: [
      {
        index: true,
        name: 'Propostas de venda',
        nameEn: 'List Sell Proposals',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.LISTAR,
        element: <PropostasVenda />,
        type: 'mercado',
      },
      {
        path: 'visualizar/:id',
        name: 'Visualizar proposta de venda',
        nameEn: 'View Sell Proposal',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.PROPOSTA_VENDA,
        hidden: true,
        element: <ViewPropostaVenda />,
        type: 'mercado',
      },
      {
        path: 'ver-contrato/:id',
        name: 'Visualizar contrato',
        nameEn: 'View Contract',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.PROPOSTA_VENDA,
        hidden: true,
        element: <ViewContratoProposta />,
        type: 'mercado',
      },
      {
        path: 'registrar-contrato/:id',
        name: 'Registrar contrato para proposta de venda',
        nameEn: 'Register Sell Proposal Contract',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.CONTRATO_VENDA,
        hidden: true,
        element: <RegistrarContratoProposta />,
        type: 'mercado',
      },
    ],
  },
  {
    name: 'Negociações',
    nameEn: 'Negotiations',
    path: 'negociacoes',
    authorizedRoles: ['registrante', 'participante'],
    icon: Icons.NEGOCIACOES,
    type: 'mercado',
    children: [
      {
        index: true,
        name: 'Negociações',
        nameEn: 'List Negotiations',
        authorizedRoles: ['registrante', 'participante'],
        icon: Icons.LISTAR,
        element: <Negociacoes />,
        type: 'mercado',
      },
      {
        path: 'visualizar/:id',
        name: 'Visualizar Negociação',
        nameEn: 'View Negotiation',
        authorizedRoles: ['registrante', 'participante'],
        hidden: true,
        icon: Icons.NEGOCIACOES,
        element: <VisualizarNegociacao />,
        type: 'mercado',
      },
      {
        path: 'mensagens/:id',
        name: 'Mensagens da negociação',
        nameEn: 'Negotiation Messages',
        authorizedRoles: ['registrante', 'participante'],
        hidden: true,
        icon: Icons.MENSAGEM,
        element: <MensagensNegociacao />,
        type: 'mercado',
      },
    ],
  },
  {
    path: 'ofertas-admin',
    name: 'Todas ofertas',
    nameEn: 'All offers',
    authorizedRoles: ['administrador'],
    type: 'geral',
    element: <PropostasCompraAdmin />,
    icon: Icons.PROPOSTA_VENDA,
  },
  {
    name: 'Todas Propostas de Venda',
    nameEn: 'All Sell Proposals',
    path: 'propostas-venda-admin',
    authorizedRoles: ['administrador'],
    icon: Icons.PROPOSTA_VENDA,
    type: 'geral',
    children: [
      {
        index: true,
        name: 'Todas Propostas de Venda',
        nameEn: 'All Sell Proposals',
        authorizedRoles: ['administrador'],
        icon: Icons.LISTAR,
        element: <PropostasVendaAdmin />,
        type: 'geral',
      },
    ],
  },
  {
    name: 'Registro de comercialização',
    nameEn: 'Comercialization Records',
    path: 'registro-comercializacao',
    authorizedRoles: ['registrante', 'participante', 'administrador'],
    icon: Icons.REGISTRO_COMERCIALIZACAO,
    element: <RegistroComercializacao />,
    type: 'geral',
  },
  {
    name: 'Histórico de lotes certificados',
    nameEn: 'Certified Batchs History',
    path: 'historico-lotes',
    authorizedRoles: ['certificador'],
    icon: Icons.HISTORICO_LOTES,
    element: <HistoricoLotes />,
    type: 'geral',
  },
  {
    path: 'aposentadoria',
    name: 'Aposentar Tokens',
    nameEn: 'Retire Tokens',
    authorizedRoles: ['participante'],
    type: 'geral',
    element: <Aposentadoria />,
    icon: Icons.APOSENTADORIA,
  },
  {
    path: 'tokens-aposentados',
    name: 'Tokens Aposentados',
    nameEn: 'Retired Tokens',
    authorizedRoles: ['administrador', 'participante'],
    type: 'geral',
    element: <TokensAposentados />,
    icon: Icons.APOSENTADORIA,
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    nameEn: 'Dashboard',
    authorizedRoles: ['administrador', 'participante', 'registrante'],
    type: 'geral',
    element: <Dashboard />,
    icon: Icons.DASHBOARD,
  },
  {
    path: 'asset-history/:key',
    name: 'Histórico do Asset',
    nameEn: 'Asset History',
    hidden: true,
    authorizedRoles: [
      'administrador',
      'participante',
      'registrante',
      'certificador',
    ],
    type: 'geral',
    element: <HistoricoAsset />,
    icon: Icons.HISTORICO,
  },
  {
    path: 'api-tokens',
    name: 'API Tokens',
    nameEn: 'API tokens',
    authorizedRoles: ['participante', 'registrante'],
    type: 'geral',
    icon: Icons.TOKEN,
    children: [
      {
        index: true,
        name: 'API Tokens',
        nameEn: 'API tokens',
        authorizedRoles: ['participante', 'registrante'],
        icon: Icons.TOKEN,
        element: <APITokens />,
      },
      {
        path: 'criar',
        name: 'Criar API Token',
        nameEn: 'Create API token',
        authorizedRoles: ['participante', 'registrante'],
        icon: Icons.ADICIONAR,
        element: <CriarToken />,
      },
    ],
  },
  {
    path: 'admin-tokens',
    name: 'API Tokens Admin',
    nameEn: 'API tokens Admin',
    authorizedRoles: ['administrador'],
    hidden: true,
    type: 'geral',
    icon: Icons.TOKEN,
    children: [
      {
        path: 'view/:id',
        name: 'API Tokens do usuário',
        nameEn: 'API tokens from user',
        authorizedRoles: ['administrador'],
        icon: Icons.TOKEN,
        element: <APITokensAdmin />,
      },
      {
        path: 'criar/:id',
        name: 'Criar API Token',
        nameEn: 'Create API token for user',
        authorizedRoles: ['administrador'],
        icon: Icons.ADICIONAR,
        element: <CriarTokenAdmin />,
      },
    ],
  },
];

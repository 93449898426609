import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

export interface ErrorMessageProps {
  errors: FieldError | undefined;
}

export const InputErrorMessage = forwardRef<HTMLSpanElement, ErrorMessageProps>(
  ({ errors }, ref) => {
    if (!errors) return null;

    // if (multipleError && errors?.types) {
    //   return (
    //     Object.entries(errors?.types).map(([type, text]) => (
    //       <S.ErrorMessage key={type} ref={ref}>
    //         {text}
    //       </S.ErrorMessage>
    //     ))
    //   )
    // }

    return (
      <span className="feedback danger" role="alert" ref={ref}>
        <i className="fas fa-times-circle" aria-hidden="true" />
        {errors?.message}
      </span>
    );
  },
);

InputErrorMessage.displayName = 'InputErrorMessage';

export enum Icons {
  ASSINAR = 'fas fa-signature',
  ADICIONAR = 'fas fa-plus',
  LISTAR = 'fas fa-list',
  LOTES = 'fas fa-table',
  PROPOSTA_VENDA = 'fas fa-sharp fa-solid fa-dollar-sign',
  CONTRATO_VENDA = 'fas fa-clipboard',
  ESTOQUE = 'fas fa-warehouse',
  REGISTRO_COMERCIALIZACAO = 'fas fa-shopping-cart',
  HISTORICO_LOTES = 'fas fa-book',
  MERCADO = 'fas fa-poll',
  OFERTAS_VENDA = 'fas fa-store',
  SALDO = 'fas fa-luggage-cart',
  APOSENTADORIA = 'fas fa-hourglass',
  REGISTRANTE = 'fas fa-registered',
  PARTICIPANTE = 'fas fa-user-alt',
  CERTIFICADOR = 'fas fa-address-card',
  USUARIO = 'fas fa-user-alt',
  DASHBOARD = 'fas fa-chart-bar',
  PLANTA = 'fas fa-city',
  NEGOCIACOES = 'fas fa-balance-scale',
  MENSAGEM = 'fas fa-comment',
  GASODUTO = 'fas fa-project-diagram',
  PONTOENTREGA = 'fas fa-arrow-circle-down',
  METODOENTREGA = 'fas fa-truck-moving',
  PRODUTOS = 'fas fa-boxes',
  HISTORICO = 'fas fa-history',
  TOKEN = 'fas fa-key',
}

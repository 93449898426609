import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryItem, HistoryItemWrapper } from './styles';
import { formatDate } from '../../../utils/formatDate';

export const ContractComponent = ({ item }: { item: HistoryItem }) => {
  const contrato = item as ContratoProposta;
  const { i18n, t } = useTranslation();

  return (
    <HistoryItemWrapper>
      <div className="date">
        {formatDate(contrato['@lastUpdated'], 'numeric', 'pt-Br')}
      </div>
      <div className="items">
        {/* <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.contractComponent.additiveCount')}
          </p>
          <p className="value">{contrato.nonceAditivo}</p>
        </HistoryItem> */}
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.contractComponent.lastTx')}
          </p>
          <p className="value">{contrato['@lastTx']}</p>
        </HistoryItem>
      </div>
    </HistoryItemWrapper>
  );
};

import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  .table-header {
    z-index: 0;
  }
`;

export const TableContainerInside = styled.div<{ minHeight?: string }>`
  ${({ minHeight }) => css`
    min-height: ${minHeight || '350px'};
    height: 100%;
    max-height: 550px;
    overflow: auto;

    & .infinite-scroll-component {
      overflow: unset !important;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    /* &::-webkit-scrollbar {
      display: none;
    } */

    /* Hide scrollbar for IE, Edge and Firefox */
    /* -ms-overflow-style: none; */
    /* scrollbar-width: none; */
  `}
`;

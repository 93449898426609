/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '../../components/Text';
import { getAssetHistoryByKey } from '../../services/HistoricoAsset';
import { getUserRole } from '../../utils/getUserRole';
import { useAuth } from '../../context/Auth';
import { LoteComponent } from './HistoryItemCompontens/loteComponent';
import { HistoryItemList } from './HistoryItemCompontens/styles';
import { OfertaComponent } from './HistoryItemCompontens/ofertaComponent';
import { PropostaVendaComponent } from './HistoryItemCompontens/propostaVendaComponent';
import { NegotiationComponent } from './HistoryItemCompontens/negotiationComponent';
import Loading from '../../components/Loading';
import { ContractComponent } from './HistoryItemCompontens/contractComponent';

const HistoricoAsset: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const role = getUserRole(user!);
  const { key } = useParams();
  const { t } = useTranslation();

  const [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);

  if (!key) {
    navigate('/');
  }

  const getAssetHistory = useCallback(async () => {
    const response = await getAssetHistoryByKey(role, { key: key as string });
    if (response) {
      setHistoryItems(response.data);
    }
  }, [key, role]);

  useEffect(() => {
    getAssetHistory();
  }, [getAssetHistory]);

  const renderHistoryItems = useCallback((itemsToRender: HistoryItem[]) => {
    return itemsToRender.map((item, idx) => {
      switch (item['@assetType']) {
        case 'loteBiometano':
          return <LoteComponent key={idx} item={item} />;
        case 'oferta':
          return <OfertaComponent key={idx} item={item} />;
        case 'propostaVenda':
          return <PropostaVendaComponent key={idx} item={item} />;
        case 'negotiation':
          return <NegotiationComponent key={idx} item={item} />;
        case 'contractProposta':
          return <ContractComponent key={idx} item={item} />;
        default:
          return null;
      }
    });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column">
            <Text as="h1">{t('assetHistoryPage.view.title')}</Text>
            <p style={{ margin: 0, marginTop: '-20px', fontSize: '11px' }}>
              {key}
            </p>
          </div>
          {historyItems.length === 0 ? (
            <Loading />
          ) : (
            <HistoryItemList>
              {renderHistoryItems(historyItems)}
            </HistoryItemList>
          )}
        </div>
      </div>
    </>
  );
};

export default HistoricoAsset;

/* eslint-disable no-console */
import axios, { AxiosResponse } from 'axios';
import api from './axios';

export const requestAPI = async <P, R>(
  method: 'get' | 'post',
  url: string,
  payload?: P,
): Promise<R | null> => {
  switch (method) {
    case 'get': {
      try {
        const base64payload = btoa(JSON.stringify(payload));
        const base64trimmed = base64payload.replaceAll('=', '');
        const requestUrl = payload ? `${url}${base64trimmed}` : url;
        const response = await api.get<R>(requestUrl, {
          method,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
    case 'post': {
      try {
        const response = await api.post<R>(url, {
          method,
          ...payload,
        });
        return response.data;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
    default:
      console.log('Invalid Method');
      return null;
  }
};

export const sendFilesAPI = async <R>(
  payload: FormData,
  url: string,
): Promise<R | null> => {
  try {
    const response = await api.post<R>(url, payload);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const docSignAPI = async (formData: FormData): Promise<any | null> => {
  try {
    const response = await api.post(
      'http://localhost:80/api/signpdf',
      formData,
      {
        method: 'post',
        headers: { 'content-type': 'multipart/form-data' },
      },
    );
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export default api;

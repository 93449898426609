import React from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { NavCard } from '../../components/NavCard';
import { NavCardsContainer } from '../../components/NavCard/styles';
import { Text } from '../../components/Text';
import Toast, { TToastOptions } from '../../components/Toast';
import { useAuthorizedRoutes } from '../../hooks/useAuthorizedRoutes';

const Home: React.FC = () => {
  const notify = (type: TToastOptions) =>
    toast(<Toast title="Title Test" description="Lorem ipsum" type={type} />);
  const routes = useAuthorizedRoutes();
  const { i18n } = useTranslation();
  const { language } = i18n;

  return (
    <div className="main-content" id="main-content">
      <p className="h2 mt-0">Home</p>
      <NavCardsContainer>
        {routes.map(
          (menuItem) =>
            menuItem.type === 'geral' && (
              <NavCard
                title={language === 'en' ? menuItem.nameEn! : menuItem.name}
                icon={menuItem.icon!}
                route={menuItem?.path || ''}
                key={menuItem.name}
              />
            ),
        )}
      </NavCardsContainer>
      {routes.filter((route) => route.type === 'mercado').length > 0 && (
        <Text as="h4">Mercado</Text>
      )}
      <NavCardsContainer>
        {routes.map(
          (menuItem) =>
            menuItem.type === 'mercado' && (
              <NavCard
                title={language === 'en' ? menuItem.nameEn! : menuItem.name}
                icon={menuItem.icon!}
                route={menuItem?.path || ''}
                key={menuItem.name}
              />
            ),
        )}
      </NavCardsContainer>
      {routes.filter((route) => route.type === 'credito').length > 0 && (
        <Text as="h4">Gas Recs</Text>
      )}
      <NavCardsContainer>
        {routes.map(
          (menuItem) =>
            menuItem.type === 'credito' && (
              <NavCard
                title={language === 'en' ? menuItem.nameEn! : menuItem.name}
                icon={menuItem.icon!}
                route={menuItem?.path || ''}
                key={menuItem.name}
              />
            ),
        )}
      </NavCardsContainer>
      {/* <div className="mt-5">
        <Button onClick={() => notify('success')}>toast success</Button>
        <Button onClick={() => notify('info')}>toast info</Button>
        <Button onClick={() => notify('warning')}>toast warn</Button>
        <Button onClick={() => notify('danger')}>toast error</Button>
      </div> */}
    </div>
  );
};

export default Home;

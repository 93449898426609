import styled from 'styled-components';

export const HistoryItemList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 20px;
  width: max-content;
`;

export const HistoryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  .date {
    padding: 4px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: #071d41;
    height: max-content;
  }
  .items {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border: 1px solid #071d41;
    padding: 8px;
    gap: 1rem;
    border-radius: 8px;
    position: relative;
    width: 100%;
  }
`;

export const HistoryItem = styled.div`
  display: flex;
  gap: 1rem;
  p {
    margin: 0;
  }
  .title {
    font-weight: bold;
  }
`;

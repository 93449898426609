import React, { lazy } from 'react';
import { IRoutes } from './types';

const Login = lazy(() => import('../pages/Login'));
const NotFound = lazy(() => import('../pages/404'));

export const PublicRoutes: IRoutes[] = [
  { name: 'Login', path: 'login', element: <Login />, type: 'geral' },
  {
    path: '*',
    name: '404',
    hidden: true,
    element: <NotFound />,
    type: 'geral',
  },
];

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryItem, HistoryItemWrapper } from './styles';
import { formatDate } from '../../../utils/formatDate';

export const PropostaVendaComponent = ({ item }: { item: HistoryItem }) => {
  const proposta = item as PropostaVenda;
  const { i18n, t } = useTranslation();

  const PropostaVendaStatus = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return [
          'Created',
          'Contract registered',
          'Waiting participant to accept',
          'Finished',
          'Cancelled',
          'Rejected',
        ];
      default:
        return [
          'Criada',
          'Contrato Registrado',
          'Aguardando Aceite do Participante',
          'Finalizada',
          'Cancelada',
          'Rejeitada',
        ];
    }
  }, [i18n.language]);

  return (
    <HistoryItemWrapper>
      <div className="date">
        {formatDate(proposta['@lastUpdated'], 'numeric', 'pt-Br')}
      </div>
      <div className="items">
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.sellProposalComponent.status')}
          </p>
          <p className="value">{PropostaVendaStatus[proposta.status]}</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.sellProposalComponent.lastTx')}
          </p>
          <p className="value">{proposta['@lastTx']}</p>
        </HistoryItem>
      </div>
    </HistoryItemWrapper>
  );
};

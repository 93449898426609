import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface IBreadcrumbProps {
  crumbs: {
    name: string;
    nameEn?: string;
    path: string | undefined;
  }[];
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ crumbs }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="br-breadcrumb col pt-1 pl-0 pr-0 mb-3xh mt-4">
      <ul className="crumb-list text-semi-bold text-down-01">
        <li className="crumb home">
          <Link to="/" className="br-button circle d-flex">
            <span className="sr-only">Home</span>
            <i className="fas fa-home" />
          </Link>
        </li>
        {crumbs.map(({ name, path, nameEn }, key) =>
          key + 1 === crumbs.length ? (
            <li className="crumb" key={key}>
              <i className="icon fas fa-chevron-right mr-half" />
              <span key={key}>{i18n.language === 'en' ? nameEn : name}</span>
            </li>
          ) : (
            <li className="crumb text-" key={key}>
              <i className="icon fas fa-chevron-right mr-half" />
              <Link to={path || '/'}>
                {i18n.language === 'en' ? nameEn : name}
              </Link>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default Breadcrumb;

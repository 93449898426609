import styled from 'styled-components';

export const NavCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: center;
  grid-gap: 2rem;

  .card-content {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;
    color: var(--color-primary-darken-01);
    text-align: center;
    font-size: var(--font-size-scale-up-02);
    font-weight: var(--font-weight-semi-bold);
    min-height: 150px;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }

    .fas,
    .fab {
      --icon-size: 2.5rem !important;
      color: var(--color-primary-lighten-01);
    }
  }
`;

/* eslint-disable react/require-default-props */
import React, { InputHTMLAttributes } from 'react';
import Flatpickr from 'react-flatpickr';
import { Controller, FieldError } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { InputErrorMessage } from '../ErrorMessage';

export interface IDatePickerProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> {
  label: string;
  name: string;
  control: any;
  errors: FieldError | undefined;
  mode?: 'single' | 'multiple' | 'range';
  enableTime?: boolean;
  required?: string | boolean;
  disabled?: boolean;
  isInsideModal?: boolean;
}

export const DatePickerRef = React.forwardRef<Flatpickr, IDatePickerProps>(
  (
    {
      label,
      name,
      control,
      errors,
      required,
      disabled,
      isInsideModal,
      mode = 'single',
      enableTime,
      ...rest
    },
    ref,
  ) => {
    const { i18n } = useTranslation();
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={rest.defaultValue?.toString() || undefined}
        rules={{ required }}
        render={({ field: { onChange, ...inputProps } }) => (
          <Flatpickr
            {...inputProps}
            ref={ref}
            onChange={(dates, currentdateString) => {
              if (currentdateString) {
                onChange(new Date(dates?.[0])?.toISOString());
              }
            }}
            options={{
              mode,
              wrap: true,
              enableTime,
              dateFormat: 'd/m/Y',
              // eslint-disable-next-line prettier/prettier
              time_24hr: true,
              ...(isInsideModal && {
                static: true,
              }),
            }}
            disabled={disabled}
          >
            {
              (
                <div className="br-datetimepicker">
                  <div
                    className={`br-input has-icon
                  ${errors ? 'danger' : ''}`}
                  >
                    <label htmlFor={label}>{label}</label>
                    <input
                      className="has-icon"
                      id={label}
                      type="datetime-local"
                      data-input="data-input"
                      placeholder={
                        i18n.language === 'pt' ? 'dd/mm/aaaa' : 'dd/mm/yyyy'
                      }
                      {...rest}
                    />

                    <button
                      className={`br-button circle small ${
                        inputProps.value && inputProps.value?.[0]
                          ? 'd-none'
                          : ''
                      }`}
                      type="button"
                      aria-label="Abrir Timepicker"
                      data-toggle
                      id={`${label}-input-btn`}
                    >
                      <i className="fas fa-calendar-alt" aria-hidden="true" />
                    </button>

                    <button
                      className={`br-button circle small ${
                        inputProps.value && inputProps.value?.[0]
                          ? ''
                          : 'd-none'
                      }`}
                      type="button"
                      aria-label="Limpar Timepicker"
                      data-clear
                      id={`${label}-input-btn`}
                      title="Limpar data"
                    >
                      <i className="fas fa-backspace" aria-hidden="true" />
                    </button>
                  </div>
                  <InputErrorMessage errors={errors} />
                </div>
              ) as never
            }
          </Flatpickr>
        )}
      />
    );
  },
);

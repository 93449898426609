import React, {
  createContext,
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { IAuthContext } from './Auth';
import { users } from '../../services/Users/mock';
import Toast from '../../components/Toast';
import { signIn } from '../../services/Users';
import { requestAPI } from '../../services/api';
import i18n from '../../utils/i18next';

export const AuthContext = createContext<IAuthContext>({
  user: null,
  login: async () => ({} as User),
  logout: async () => ({} as Promise<void>),
  getUserData: async () => ({} as User),
});

export const userType = new Map<string, string>([
  ['255', 'administrador'],
  ['1', 'participante'],
  ['2', 'registrante'],
  ['3', 'certificador'],
]);

export const findUserTypeByValue = (key: string) => {
  const type = [...userType].find((item) => item[1].includes(key));
  if (type) return type[0];
  return null;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);

  const login = useCallback(
    async (credentials: UserCredentials): Promise<User | null> => {
      const res = await signIn(credentials);
      if (res) {
        // localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        toast(
          <Toast
            type="success"
            title={
              i18n.language === 'pt'
                ? 'Bem vindo ao Petro-Biometano'
                : 'Welcome to Petro-Biomethane'
            }
            description={res.Name as string}
          />,
        );
        return res;
      }
      toast(
        <Toast
          type="danger"
          title={
            i18n.language === 'pt'
              ? 'Falha ao realizar o login'
              : 'Failed to login'
          }
          description=""
        />,
      );
      return null;
    },
    [],
  );

  const logout = useCallback(async (): Promise<void> => {
    const res = await requestAPI<any, User>('get', '/auth/signOut');
    if (res) {
      setUser(null);
      toast(
        <Toast
          type="info"
          title={
            i18n.language === 'pt'
              ? 'Você saiu do Petro-Biometano'
              : 'You logged out of Petro-Biomethane'
          }
          description=""
        />,
      );
      return;
    }
    toast(
      <Toast
        type="danger"
        title={
          i18n.language === 'pt'
            ? 'Falha ao realizar o logout'
            : 'Failed to logout'
        }
        description=""
      />,
    );
  }, []);

  const getUserData = useCallback(async () => {
    const response = await requestAPI<any, User>('get', '/getUserDataLogged');
    if (response) {
      setUser(response);
    }
  }, []);

  useEffect(() => {
    if (user === null) {
      getUserData();
    }
  }, [getUserData, user]);

  // Listen to localStorage manual changes and logout

  window.addEventListener(
    'storage',
    () => {
      setUser(null);
      localStorage.removeItem('user');
    },
    false,
  );

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      getUserData,
    }),
    [getUserData, login, logout, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

import React, { useState } from 'react';

export type TToastOptions = 'danger' | 'success' | 'info' | 'warning';

export interface IToast {
  type: TToastOptions;
  title: string;
  description: string;
  closeToast?(): void;
}

const typeModifiers = {
  danger: <i className="fas fa-times-circle fa-lg" aria-hidden="true" />,
  success: <i className="fas fa-check-circle fa-lg" aria-hidden="true" />,
  info: <i className="fas fa-info-circle fa-lg" aria-hidden="true" />,
  warning: (
    <i className="fas fa-exclamation-triangle fa-lg" aria-hidden="true" />
  ),
};

const Toast: React.FC<IToast> = ({ title, type, description, closeToast }) => {
  const [close, setClose] = useState(false);
  return (
    <div
      className={`br-message ${type} m-0`}
      style={{ display: close ? 'none' : 'default' }}
      role="alert"
    >
      <div className="icon">{typeModifiers[type || 'info']}</div>
      <div className="content">
        <strong className="text-semi-bold">{title}</strong>
        <p>{description}</p>
      </div>
      <div className="close">
        <button
          className="br-button circle small"
          type="button"
          id="closeToast"
          aria-label="Fechar"
          onClick={() => (closeToast ? closeToast() : setClose(true))}
        >
          <i className="fas fa-times" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default Toast;

import React from 'react';
import { NavItem } from './NavItem';
import { useAuthorizedRoutes } from '../../hooks/useAuthorizedRoutes';

const Nav: React.FC = () => {
  const authorizedRoutes = useAuthorizedRoutes();

  return (
    <nav className="menu-body">
      {authorizedRoutes.map((menuItem) => {
        if (!menuItem?.children)
          return <NavItem key={menuItem.name} {...menuItem} />;

        // Don't create a dropdown if there is only one child
        if (menuItem?.children.filter((child) => !child.hidden).length === 1)
          return (
            <NavItem
              key={menuItem.name}
              {...{
                name: menuItem.children[0].name,
                nameEn: menuItem.children[0].nameEn,
                path: menuItem.children[0].index
                  ? menuItem.path
                  : menuItem.children[0].path,
                icon: menuItem.icon,
              }}
            />
          );

        return <NavItem key={menuItem.name} {...menuItem} />;
      })}
    </nav>
  );
};

export default Nav;

import React, { useCallback, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '../Button';
import { Input } from '../FormField/Input';
import { Select } from '../FormField/Select';
import { requestAPI } from '../../services/api';
import { getEndereco } from '../../services/Endereco';
import Switch from '../FormField/Switch';
import { M3TOMMBTU } from '../../utils/Constants';
import { MouseOverInfo } from '../FormField/MouseOverInfo';
import getFieldErrors from '../../utils/getFieldErrors';
import { DatePickerRef } from '../FormField/DatePicker/datePickerWithRef';

type RegisterTokenFormProps = UseFormReturn<CreateTokenPayload> & {
  onSubmit: (data: CreateTokenPayload) => void;
};

const RegisterTokenForm: React.FC<RegisterTokenFormProps> = (props) => {
  const {
    onSubmit,
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = props;

  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column align-items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="col-12 col-sm-10 col-md-8 d-flex flex-column"
      >
        <div className="d-flex justify-content-center">
          <div className="col-12 col-md-6">
            <Input
              label={t('apiTokensPage.register.fields.tokenName.label')}
              {...register(
                'applicationName',
                getFieldErrors(
                  'apiTokensPage.register.fields.tokenName.errors',
                ),
              )}
              className="mb-3"
              icon="fa fa-code"
              placeholder={t(
                'apiTokensPage.register.fields.tokenName.placeholder',
              )}
              errors={errors?.applicationName}
            />
            <DatePickerRef
              label={t('apiTokensPage.register.fields.dateExpire.label')}
              name="dateExpire"
              required={t(
                'apiTokensPage.register.fields.dateExpire.errors.required',
              )}
              control={control}
              errors={errors.dateExpire}
            />
          </div>
        </div>
        <div className="align-self-center mt-5">
          <Button type="submit" density="large" block loading={isSubmitting}>
            {t('apiTokensPage.register.submitButton')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RegisterTokenForm;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Toast from '../../components/Toast';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import { Text } from '../../components/Text';
import { useAuth, userType } from '../../context/Auth';
import {} from '../../services/Lotes';
import { Icons } from '../../utils/Icons';
import { getUserRole } from '../../utils/getUserRole';
import { deletarToken, getTokens } from '../../services/APITokens';

const APITokens: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const [tokens, setTokens] = useState<Token[]>([]);
  const role = getUserRole(user!);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedToken, setSelectedtoken] = useState('');

  const closeDeleteModal = useCallback(() => {
    setDeleteModalOpen(false);
    setSelectedtoken('');
  }, []);

  const openDeleteModal = useCallback((tokenKey: string) => {
    setDeleteModalOpen(true);
    setSelectedtoken(tokenKey);
  }, []);

  const listarTokens = useCallback(async () => {
    const response = await getTokens(role);
    if (response) {
      setTokens(response.data);
    }
  }, [role]);

  useEffect(() => {
    listarTokens();
  }, [listarTokens]);

  const {
    handleSubmit: deletarTokenHandleSubmit,
    formState: { isSubmitting: deletarTokenSubmitting },
  } = useForm({
    mode: 'onChange',
  });

  const apagarToken = useCallback(async () => {
    const payload: DeleteTokenPayload = {
      idToken: selectedToken,
    };
    const response = await deletarToken(payload, role);
    if (response) {
      toast(
        <Toast
          title={t('apiTokensPage.index.modals.deleteToken.messages.success')}
          description=""
          type="success"
        />,
      );
      setDeleteModalOpen(false);
      setSelectedtoken('');
      listarTokens();
    }
  }, [listarTokens, role, selectedToken, t]);

  const thead = [
    t('apiTokensPage.index.table.idCode'),
    t('apiTokensPage.index.table.tokenName'),
    t('apiTokensPage.index.table.expireDate'),
    t('apiTokensPage.index.table.userKey'),
    t('apiTokensPage.index.table.actions'),
  ];

  return (
    <>
      <Modal
        title={t('apiTokensPage.index.modals.deleteToken.label')}
        isOpen={deleteModalOpen}
        onClose={{
          action: closeDeleteModal,
          label: t('apiTokensPage.index.modals.deleteToken.closeButton'),
        }}
        onConfirm={{
          action: deletarTokenHandleSubmit(apagarToken),
          label: t('apiTokensPage.index.modals.deleteToken.acceptButton'),
          loading: deletarTokenSubmitting,
        }}
      >
        <h3>{t('apiTokensPage.index.modals.deleteToken.tokenId')}</h3>
        <p>{selectedToken}</p>
      </Modal>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <Text as="h1">{t('apiTokensPage.index.title')}</Text>
            {user?.AdminOrganization && (
              <Button
                onClick={() => navigate('/api-tokens/criar')}
                variant="primary"
              >
                <i className={Icons.TOKEN} aria-hidden="true" />
                {t('apiTokensPage.index.createButton')}
              </Button>
            )}
          </div>
          <Table
            thead={thead}
            caption={t('apiTokensPage.index.table.tableName')}
            data={tokens}
          >
            {tokens !== null &&
              tokens.length > 0 &&
              tokens.map((item, index) => {
                return (
                  item && (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.applicationName}</td>
                      <td>{item.dateExpire}</td>
                      <td>{item.userKey}</td>
                      <td>
                        <span className="d-flex">
                          <Button
                            variant="circle"
                            onClick={() => openDeleteModal(item.id)}
                            title={t('apiTokensPage.index.deleteTokenButton')}
                            id="deleteTokenButton"
                          >
                            <i className="fas fa-trash" aria-hidden="true" />
                          </Button>
                        </span>
                      </td>
                    </tr>
                  )
                );
              })}
          </Table>
        </div>
      </div>
    </>
  );
};

export default APITokens;

import React from 'react';
import { toast } from 'react-toastify';
import { AxiosRequestConfig } from 'axios';
import { requestAPI } from '../api';
import Toast from '../../components/Toast';

type ListarUsuarios = PaginationParams & ListarUsuariosPayload;

export const listarUsuarios = (params: ListarUsuarios): AxiosRequestConfig => {
  return {
    url: '/admin/getUsers',
    method: 'GET',
    params,
  };
};

export const getUser = (params: { userId: string }): AxiosRequestConfig => {
  return {
    url: '/admin/getUserTypeByKey',
    method: 'GET',
    params,
  };
};

export const signIn = async (
  payload: UserCredentials,
): Promise<User | null> => {
  const url = '/auth/signIn';
  const response = await requestAPI<UserCredentials, User>(
    'post',
    url,
    payload,
  );
  if (response) {
    return response;
  }
  return null;
};

export const criarUsuario = async (
  payload: CreateUserPayload,
): Promise<User | null> => {
  const url = '/admin/createUser';
  const response = await requestAPI<CreateUserPayload, User>(
    'post',
    url,
    payload,
  );
  if (response) {
    return response;
  }
  return null;
};

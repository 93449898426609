import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryItem, HistoryItemWrapper } from './styles';
import { formatDate } from '../../../utils/formatDate';

export const NegotiationComponent = ({ item }: { item: HistoryItem }) => {
  const negociacao = item as Negociacao;
  const { i18n, t } = useTranslation();

  const NegociacaoStatus = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return [
          '',
          'Open',
          'Finished',
          'Cancelled',
          'Confirmed by Buyer',
          'Confirmed by Seller',
          'Confirmed',
        ];
      default:
        return [
          '',
          'Aberta',
          'Encerrada',
          'Cancelada',
          'Confirmada pelo Comprador',
          'Confirmada pelo Vendedor',
          'Confirmada',
        ];
    }
  }, [i18n.language]);

  const ModalDelivery = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return ['', 'Firm', 'Interruptible'];
      default:
        return ['', 'Firme', 'Interruptível'];
    }
  }, [i18n.language]);

  return (
    <HistoryItemWrapper>
      <div className="date">
        {formatDate(negociacao['@lastUpdated'], 'numeric', 'pt-Br')}
      </div>
      <div className="items">
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.negotiationComponent.status')}
          </p>
          <p className="value">{NegociacaoStatus[negociacao.status]}</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.negotiationComponent.lastTx')}
          </p>
          <p className="value">{negociacao['@lastTx']}</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t(
              'assetHistoryPage.components.negotiationComponent.modalDelivery',
            )}
          </p>
          <p className="value">
            {ModalDelivery[negociacao.modalDelivery] || '-'}
          </p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t(
              'assetHistoryPage.components.negotiationComponent.modalLogistico',
            )}
          </p>
          <p className="value">
            {negociacao.modalLogistico?.description || '-'}
          </p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t(
              'assetHistoryPage.components.negotiationComponent.locationDelivery',
            )}
          </p>
          <p className="value">
            {negociacao.locationDelivery?.descriptionlocal || '-'}
          </p>
        </HistoryItem>
      </div>
    </HistoryItemWrapper>
  );
};

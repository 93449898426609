import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { changeLanguage } from 'i18next';
import Logo from '../../assets/GoGasByGoLedger.png';
import LogoSmall from '../../assets/GoGasSmall.png';
import { useAuth } from '../../context/Auth';
import Menu from '../Menu';

import { LinkList, FunctionalitiesList } from './styles';

const Header: React.FC = () => {
  const [variant, setVariant] = useState('');
  const [openFastAccess, setOpenFastAccess] = useState(false);
  const [sytemFunctionalities, setSytemFunctionalities] = useState(false);

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const height = document.getElementById('header')?.offsetHeight;

      if (height && window.scrollY > height) {
        setVariant('sticky compact');
      } else {
        setVariant('');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { t, i18n } = useTranslation();

  const handleChangeLanguage = () => {
    const { language } = i18n;
    if (language === 'pt') {
      changeLanguage('en');
    } else {
      changeLanguage('pt');
    }
  };

  return (
    <header
      className={`br-header ${variant}`}
      style={{ zIndex: 10 }}
      id="header"
      data-sticky="data-sticky"
    >
      <div className="container-lg">
        <div className="header-top">
          <div className="header-logo">
            {variant === '' ? (
              <img src={Logo} style={{ transform: 'scale(1.3)' }} alt="logo" />
            ) : (
              <img
                src={LogoSmall}
                alt="logo"
                style={{ transform: 'scale(1.3) translateX(25%)' }}
              />
            )}

            <span className="br-divider vertical mx-half mx-sm-1" />
            <div className="header-sign">{t('header.logoText')}</div>
          </div>
          <div className="header-actions">
            <div className="header-links dropdown">
              <button
                className="br-button circle small"
                type="button"
                data-toggle="dropdown"
                aria-label="Abrir Acesso Rápido"
                onClick={() => setOpenFastAccess((state) => !state)}
              >
                <i className="fas fa-ellipsis-v" aria-hidden="true" />
              </button>
              {/* <LinkList
                className="br-list"
                visibility={String(openFastAccess) as 'true' | 'false'}
              >
                <div className="header">
                  <div className="title">Acesso Rápido</div>
                </div>
                <a className="br-item" href="/">
                  Link de acesso 1
                </a>
                <a className="br-item" href="/">
                  Link de acesso 2
                </a>
                <a className="br-item" href="/">
                  Link de acesso 3
                </a>
                <a className="br-item" href="/">
                  Link de acesso 4
                </a>
              </LinkList> */}
            </div>
            <span className="br-divider vertical mx-half mx-sm-1" />
            <div className="header-functions dropdown">
              <button
                className="br-button circle small"
                type="button"
                data-toggle="dropdown"
                aria-label="Abrir Funcionalidades do Sistema"
                onClick={() => setSytemFunctionalities((state) => !state)}
              >
                <i className="fas fa-th" aria-hidden="true" />
              </button>
              <FunctionalitiesList
                className="br-list"
                visibility={String(sytemFunctionalities) as 'true' | 'false'}
              >
                <div className="header">
                  <div className="title">Funcionalidades do Sistema</div>
                </div>
                <div className="align-items-center br-item">
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Funcionalidade 1"
                  >
                    <i className="fas fa-chart-bar" aria-hidden="true" />
                    <span className="text">Funcionalidade 1</span>
                  </button>
                </div>
                <div className="align-items-center br-item">
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Funcionalidade 2"
                  >
                    <i className="fas fa-headset" aria-hidden="true" />
                    <span className="text">Funcionalidade 2</span>
                  </button>
                </div>
                <div className="align-items-center br-item">
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Funcionalidade 3"
                  >
                    <i className="fas fa-comment" aria-hidden="true" />
                    <span className="text">Funcionalidade 3</span>
                  </button>
                </div>
                <div className="align-items-center br-item">
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Funcionalidade 4"
                  >
                    <i className="fas fa-adjust" aria-hidden="true" />
                    <span className="text">Funcionalidade 4</span>
                  </button>
                </div>
                <div className="align-items-center br-item">
                  <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Trocar idioma"
                    onClick={handleChangeLanguage}
                  >
                    <i className="fas fa-language" aria-hidden="true" />
                    <span className="text">Trocar idioma</span>
                  </button>
                </div>
              </FunctionalitiesList>
            </div>
            <div className="header-search-trigger">
              <button
                className="br-button circle"
                type="button"
                aria-label="Trocar idioma"
                onClick={handleChangeLanguage}
              >
                <i className="fas fa-language" aria-hidden="true" />
              </button>
            </div>
            <div className="header-login d-flex">
              {!user && (
                <div className="header-sign-in">
                  <button
                    className="br-sign-in small"
                    type="button"
                    data-trigger="login"
                    onClick={() => navigate('/login')}
                  >
                    <i className="fas fa-user" aria-hidden="true" />
                    <span className="d-sm-inline">
                      {t('header.loginButton.signIn')}
                    </span>
                  </button>
                </div>
              )}
              {user && (
                <div className="align-items-center br-item">
                  <div className="header-sign-in">
                    <button
                      className="br-sign-in small"
                      type="button"
                      data-trigger="logout"
                      id="logout"
                      onClick={() => logout()}
                    >
                      <i className="fas fa-sign-out-alt" aria-hidden="true" />
                      <span className="d-sm-inline">
                        {t('header.loginButton.logout')}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              <div className={`header-avatar ${!user ? 'd-none' : ''}`}>
                <div className="avatar dropdown">
                  <span className="br-avatar" title="Fulana da Silva">
                    <span className="image">
                      <img
                        src="https://picsum.photos/id/823/400"
                        alt="Avatar"
                      />
                    </span>
                  </span>
                  {/* <button
                    className="br-button circle small"
                    type="button"
                    aria-label="Abrir Menu de usuário"
                    data-toggle="dropdown"
                  >
                    <i className="fas fa-angle-down" aria-hidden="true" />
                  </button> */}
                  <div className="br-notification show">
                    <div className="notification-header">
                      <div className="row">
                        <div className="col-10">
                          <span className="text-bold">Fulano da Silva</span>
                          <br />
                          <small>
                            <a href="/">nome.sobrenome@dominio.gov</a>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="notification-body">
                      <div className="br-tab">
                        <nav className="tab-nav">
                          <ul>
                            <li className="tab-item">
                              <button
                                type="button"
                                data-panel="notif-item805"
                                aria-label="notif-item805"
                              >
                                <span className="name">
                                  <span className="icon">
                                    <i
                                      className="fas fa-image"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="name">Item</span>
                                </span>
                              </button>
                            </li>
                            <li className="tab-item is-active">
                              <button
                                type="button"
                                data-panel="notif-item806"
                                aria-label="notif-item806"
                              >
                                <span className="name">
                                  <span className="icon">
                                    <i
                                      className="fas fa-image"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="name">Item</span>
                                </span>
                              </button>
                            </li>
                          </ul>
                        </nav>
                        <div className="tab-content">
                          <div className="tab-panel" id="notif-item805">
                            <div className="br-list">
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <i
                                  className="fas fa-heartbeat"
                                  aria-hidden="true"
                                />
                                Link de Acesso
                              </button>
                            </div>
                          </div>
                          <div
                            className="tab-panel is-active"
                            id="notif-item806"
                          >
                            <div className="br-list">
                              <button className="br-item" type="button">
                                <span className="br-tag status small warning" />
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  25 de out
                                </span>
                                <span>
                                  Nostrud consequat culpa ex mollit aute. Ex ex
                                  veniam ea labore laboris duis duis elit. Ex
                                  aute dolor enim aute Lorem dolor. Duis labore
                                  ad anim culpa. Non aliqua excepteur sunt
                                  eiusmod ex consectetur ex esse laborum velit
                                  ut aute.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  24 de out
                                </span>
                                <span>
                                  Labore nulla elit laborum nulla duis. Deserunt
                                  ad nulla commodo occaecat nulla proident ea
                                  proident aliquip dolore sunt nulla. Do sit eu
                                  consectetur quis culpa. Eiusmod minim irure
                                  sint nulla incididunt occaecat ipsum mollit in
                                  ut. Minim adipisicing veniam adipisicing velit
                                  nostrud duis consectetur aute nulla deserunt
                                  culpa aliquip.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="br-tag status small warning" />
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  03 de out
                                </span>
                                <span>
                                  Duis qui dolor dolor qui sint consectetur.
                                  Ipsum eu dolore ex anim reprehenderit laborum
                                  commodo. Labore do ut nulla eiusmod
                                  consectetur.
                                </span>
                              </button>
                              <span className="br-divider" />
                              <button className="br-item" type="button">
                                <span className="text-bold">Título</span>
                                <span className="text-medium mb-2">
                                  16 de mai
                                </span>
                                <span>
                                  Sunt velit dolor enim mollit incididunt irure
                                  est. Ad ea Lorem culpa quis occaecat sunt in
                                  exercitation nisi. Sit laborum laborum dolor
                                  culpa ipsum velit. Non nulla nisi dolore et
                                  anim consequat officia deserunt amet qui.
                                  Incididunt exercitation irure labore ut Lorem
                                  culpa. Dolore ea irure pariatur ullamco culpa
                                  veniam amet dolor in fugiat pariatur ut. Sit
                                  non ut enim et incididunt tempor irure
                                  pariatur ex proident labore cillum dolore
                                  nisi.
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="header-menu">
            <Menu />
            <div className="header-info">
              <div className="header-title">{t('header.menuTitle')}</div>
              {user && (
                <div className="header-subtitle">
                  {t('header.welcomeText')} {user.Name}!
                </div>
              )}
            </div>
          </div>
          {/* <div className="header-search">
            <div className="br-input has-icon">
              <label htmlFor="main-searchbox">Texto da pesquisa</label>
              <input
                className="has-icon"
                id="main-searchbox"
                type="search"
                placeholder="O que você procura?"
              />
              <button
                className="br-button circle small"
                type="button"
                aria-label="Pesquisar"
              >
                <i className="fas fa-search" aria-hidden="true" />
              </button>
            </div>
            <button
              className="br-button circle search-close"
              type="button"
              aria-label="Fechar Busca"
              data-dismiss="search"
            >
              <i className="fas fa-times" aria-hidden="true" />
            </button>
          </div> */}
        </div>
      </div>
    </header>
  );
};

export default Header;

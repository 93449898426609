import styled from 'styled-components';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const StyledToastContainer = styled(ToastContainer).attrs({
  position: 'top-right',
  newestOnTop: true,
  closeButton: false,
  closeOnClick: false,
  autoClose: 5000,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  transition: Slide,
})`
  .Toastify__toast {
    padding: 0;
    background-color: var(--color-secondary-01);
    font-family: var(--font-family-base);
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__progress-bar {
    background: var(--color-primary-lighten-02);
  }
`;

import React from 'react';
import { toast } from 'react-toastify';
import { AxiosRequestConfig } from 'axios';
import Toast from '../../components/Toast';
import { requestAPI } from '../api';

export const listarLotesInfinite = (
  params: any,
  role: string,
): AxiosRequestConfig => {
  let url;

  if (role === 'registrante') {
    url = 'listarLotesBiometano';
  } else {
    url = 'listarLotes';
  }

  return {
    method: 'GET',
    url: `/${role}/${url}`,
    params,
  };
};

export const listarLotesBiometano = async (
  payload: ListarLotesBiometanoPayload,
): Promise<LoteBiometanoResponse | null> => {
  const url = `/registrante/listarLotesBiometano?request=`;
  const response = await requestAPI<
    ListarLotesBiometanoPayload,
    LoteBiometanoResponse
  >('get', url, payload);

  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Falha ao listar lotes"
      type="danger"
    />,
  );
  return null;
};

export const listarLotesBiometanoCertificador = async (
  payload: ListarLotesCertificadorPayload,
): Promise<LoteBiometanoResponse | null> => {
  const url = `/certificador/listarLotes?request=`;
  const response = await requestAPI<
    ListarLotesCertificadorPayload,
    LoteBiometanoResponse
  >('get', url, payload);

  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Failed ao obter dados"
      type="danger"
    />,
  );
  return null;
};

export const registrarLoteBiometano = async (
  payload: RegistrarLoteBiometanoPayload,
): Promise<LoteBiometano | null> => {
  const url = '/registrante/registrarLoteBiometano';
  const response = await requestAPI<
    RegistrarLoteBiometanoPayload,
    LoteBiometano
  >('post', url, payload);

  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Falha ao registar o lote"
      type="danger"
    />,
  );
  return null;
};

export const autenticarLoteBiometano = async (
  payload: AutenticarLotePayload,
): Promise<LoteBiometano | null> => {
  const url = '/certificador/confirmarAutenticidadeBiometano';
  const response = await requestAPI<AutenticarLotePayload, LoteBiometano>(
    'post',
    url,
    payload,
  );
  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Falha ao autenticar o lote"
      type="danger"
    />,
  );
  return null;
};

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { StyledToastContainer } from './components/Toast/styles';
import { AuthProvider } from './context/Auth';
import GlobalStyle from './styles/global';

import './utils/i18next';
import Loading from './components/Loading';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
    <StyledToastContainer />
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'),
);

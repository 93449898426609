/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-access-key */
import React, { useMemo } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/Auth';
import { PrivateRoutes } from '../../routes/PrivateRoutes';

// import { useAuthorizedRoutes } from '../../hooks/useAuthorizedRoutes';
import Breadcrumb from '../Breadcrumb';
import Header from '../Header';

const replacePathParams = (
  path: string,
  params: { [x: string]: string | undefined },
) =>
  Object.keys(params).reduce((pathWithParam, param) => {
    return pathWithParam.replace(`:${param}`, params[param] || '');
  }, path);

export const Layout: React.FC = () => {
  // const menuOptions = useAuthorizedRoutes();
  const { i18n, t } = useTranslation();

  const location = useLocation();
  const match = useMatch(location.pathname);
  const { user } = useAuth();

  const crumbs = useMemo(() => {
    if (!match) return [];

    return PrivateRoutes.filter(
      (route) =>
        match?.pathname.includes(route.path as string) && route.path !== '/',
    ).map(({ path, name, nameEn }) => ({
      path: Object.keys(match.params).length
        ? replacePathParams(path as string, match.params)
        : path,
      name,
      nameEn,
    }));
  }, [match]);

  return (
    <div className="template-base">
      <nav className="br-skiplink">
        <a className="br-item" href="#main-content" accessKey="1">
          Ir para o conteúdo (1/4) <span className="br-tag text ml-1">1</span>
        </a>
        <a className="br-item" href="#header-navigation" accessKey="2">
          Ir para o menu (2/4) <span className="br-tag text ml-1">2</span>
        </a>
        <a className="br-item" href="#main-searchbox" accessKey="3">
          Ir para a busca (3/4) <span className="br-tag text ml-1">3</span>
        </a>
        <a className="br-item" href="#footer" accessKey="4">
          Ir para o rodapé (4/4) <span className="br-tag text ml-1">4</span>
        </a>
      </nav>

      <Header />

      <main className="d-flex flex-fill mb-5" id="main">
        <div className="container-lg d-flex">
          <div className="row flex-fill">
            <div className="col mb-5">
              {user && location.pathname !== '/' ? (
                <Breadcrumb crumbs={crumbs} />
              ) : null}

              <div
                className={`main-content pl-sm-3 ${
                  !user || location.pathname === '/' ? 'mt-5' : ''
                }`}
                id="main-content"
              >
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="br-footer pt-3" id="footer">
        <div className="container-lg">
          <div className="info">
            <div
              className="text-down-01 text-medium pb-3"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <p style={{ textAlign: 'center', fontSize: '12px' }}>
                {' '}
                {t('footer.text.0')}
                <strong>
                  <a href="#">{t('footer.text.1')}</a>.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </footer>
      <div className="br-cookiebar default d-none" tabIndex={-1} />
    </div>
  );
};

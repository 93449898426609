import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryItem, HistoryItemWrapper } from './styles';
import { formatDate } from '../../../utils/formatDate';

export const OfertaComponent = ({ item }: { item: HistoryItem }) => {
  const oferta = item as Oferta;
  const { i18n, t } = useTranslation();

  const StatusOferta = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return ['Open', 'Executed', 'Parcially Executed', 'Cancelled'];
      default:
        return ['Aberta', 'Executada', 'Executada Parcialmente', 'Cancelada'];
    }
  }, [i18n.language]);

  return (
    <HistoryItemWrapper>
      <div className="date">
        {formatDate(oferta['@lastUpdated'], 'numeric', 'pt-Br')}
      </div>
      <div className="items">
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.offerComponent.status')}
          </p>
          <p className="value">{StatusOferta[oferta.status]}</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.offerComponent.lastTx')}
          </p>
          <p className="value">{oferta['@lastTx']}</p>
        </HistoryItem>
      </div>
    </HistoryItemWrapper>
  );
};

import React from 'react';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { requestAPI } from '../api';
import Toast from '../../components/Toast';

export const criarToken = async (
  payload: CreateTokenPayload,
  role?: string,
): Promise<Token | null> => {
  const response = await requestAPI<any, Token>(
    'post',
    `/${role === 'administrador' ? 'admin' : role}/apiTokens/create`,
    payload,
  );
  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Falha ao criar token"
      type="danger"
    />,
  );
  return null;
};

export const deletarToken = async (
  payload: DeleteTokenPayload,
  role?: string,
): Promise<DeleteTokenReponse | null> => {
  const response = requestAPI<any, DeleteTokenReponse>(
    'post',
    `/${role === 'administrador' ? 'admin' : role}/apiTokens/revoke`,
    payload,
  );
  if (response) {
    return response;
  }
  toast(
    <Toast
      title="Algo deu errado"
      description="Falha ao deletar token"
      type="danger"
    />,
  );
  return null;
};

export const getTokens = async (role: string) => {
  const response = requestAPI<any, GetTokensResponse>(
    'get',
    `/${role}/apiTokens/getTokensUser`,
  );
  return response;
};

export const getTokensAdmin = async (role: string, userId: string) => {
  const response = requestAPI<any, GetTokensResponse>(
    'get',
    `/admin/apiTokens/getTokensUser?request=`,
    { userId },
  );
  return response;
};

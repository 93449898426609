import { requestAPI } from '../api';

export const getAssetHistoryByKey = async (
  role: string,
  payload: { key: string },
) => {
  const url = `${role}/getHistory?request=`;
  const response = await requestAPI<any, PaginatedResult<HistoryItem[]>>(
    'get',
    url,
    payload,
  );
  return response;
};

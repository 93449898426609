import styled from 'styled-components';

type Props = {
  visibility: 'true' | 'false';
};

const displayModifier = {
  true: 'block',
  false: 'none',
};

export const FunctionalitiesList = styled.div<Props>`
  &.br-list {
    display: flex;

    @media (max-width: 1280px) {
      display: ${({ visibility }) => displayModifier[visibility]};
    }
  }
`;

export const LinkList = styled.div<Props>`
  &.br-list {
    display: flex;

    @media (max-width: 1280px) {
      display: ${({ visibility }) => displayModifier[visibility]};
    }
  }
`;

import styled from 'styled-components';

export const CreatedTokenMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .alert {
    background-color: #ebbd34;
    padding: 8px;
    border-radius: 10px;
    text-align: center;
    p {
      font-weight: bold;
    }
  }

  .token {
    word-break: break-all;
    flex-wrap: wrap;
    background-color: lightgray;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    border-radius: 10px;
  }
`;

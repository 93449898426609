import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryItem, HistoryItemWrapper } from './styles';
import { formatDate } from '../../../utils/formatDate';

export const LoteComponent = ({ item }: { item: HistoryItem }) => {
  const lote = item as LoteBiometano;
  const { i18n, t } = useTranslation();

  const BatchStatus = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return ['Pending', 'Confirmed'];
      default:
        return ['Pendente', 'Confirmado'];
    }
  }, [i18n.language]);
  return (
    <HistoryItemWrapper>
      <div className="date">
        {formatDate(lote['@lastUpdated'], 'numeric', 'pt-Br')}
      </div>
      <div className="items">
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.batchComponent.quantity')}
          </p>
          <p className="value">{lote.quantity} unid.</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.batchComponent.status')}
          </p>
          <p className="value">{BatchStatus[lote.status]}</p>
        </HistoryItem>
        <HistoryItem>
          <p className="title">
            {t('assetHistoryPage.components.batchComponent.lastTx')}
          </p>
          <p className="value">{lote['@lastTx']}</p>
        </HistoryItem>
      </div>
    </HistoryItemWrapper>
  );
};

import { type } from 'os';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import RegisterLotesForm from '../../components/RegisterLotesForm';
import { Text } from '../../components/Text';
import Toast from '../../components/Toast';
import { useAuth } from '../../context/Auth';
import { registrarLoteBiometano } from '../../services/Lotes';
import { Icons } from '../../utils/Icons';
import { criarToken } from '../../services/APITokens';
import RegisterTokenForm from '../../components/RegisterTokenForm';
import Modal from '../../components/Modal';
import { CreatedTokenMessage } from './styles';
import { getUserRole } from '../../utils/getUserRole';

const CriarToken: React.FC = () => {
  const methods = useForm<CreateTokenPayload>();
  const { user } = useAuth();
  const role = getUserRole(user!);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [createdTokenModal, setCreatedTokenModal] = useState(false);
  const [token, setToken] = useState('');

  const closeTokenModal = () => {
    setCreatedTokenModal(false);
    setToken('');
    navigate('/api-tokens');
  };

  const copyToken = useCallback(() => {
    navigator.clipboard.writeText(token);
    toast(
      <Toast
        title={t('apiTokensPage.register.modals.createdToken.messages.success')}
        description=""
        type="success"
      />,
    );
  }, [t, token]);

  const formSubmitHandler = useCallback(async (data: CreateTokenPayload) => {
    const payload: CreateTokenPayload = {
      ...data,
    };
    const response = await criarToken(payload, role);
    if (response) {
      setToken(response.token);
      setCreatedTokenModal(true);
    }
  }, []);
  return (
    <>
      <Modal
        isOpen={createdTokenModal}
        title="Token criado!"
        onClose={{
          action: closeTokenModal,
          label: t('apiTokensPage.register.closeCreatedModal'),
        }}
      >
        <CreatedTokenMessage>
          <div className="alert">
            <p className="title">
              <i className="fas fa-exclamation-triangle" />
              {t('apiTokensPage.register.modals.createdToken.attention')}
            </p>
            <p className="message">
              {t('apiTokensPage.register.modals.createdToken.message')}
            </p>
          </div>
          <Button
            variant="primary"
            onClick={() => {
              copyToken();
            }}
          >
            <i className="fas fa-copy" />
            {t('apiTokensPage.register.modals.createdToken.copyButton')}
          </Button>
          <div className="token">{token}</div>
        </CreatedTokenMessage>
      </Modal>
      <div className="row">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <Text as="h1">{t('apiTokensPage.register.title')}</Text>
            <Button onClick={() => navigate('/api-tokens')} variant="secondary">
              <i className={Icons.TOKEN} aria-hidden="true" />
              {t('apiTokensPage.register.viewButton')}
            </Button>
          </div>
          <RegisterTokenForm {...methods} onSubmit={formSubmitHandler} />
        </div>
      </div>
    </>
  );
};

export default CriarToken;
